<template>
    <div>
        <taggroup-form :entity-id="entityId"></taggroup-form>
    </div>
</template>

<script>
import taggroupForm from '@/components/pages/tagGroups/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: { taggroupForm },

    mixins: [dirtyForm],

    data() {
        return {
            entityId: null,
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                this.entityId = route.params.id
            },
        },
    },
}
</script>
