<template>
    <div>
        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            :disable-save-button="activeTab > 0"
            :disable-delete-button="activeTab > 0 || form.id === userId"
            @created="created($event)"
            @updated="updated($event)"
            @cancelled="cancelled"
            @deleted="deleted"
        >
            <template v-if="!isSuperAdmin || !form['@id']">
                <general-form-part v-model="form"></general-form-part>
            </template>
            <template v-else>
                <v-tabs-items v-model="activeTab">
                    <!-- GENERAL TAB -->
                    <v-tab-item :transition="false" :reverse-transition="false">
                        <general-form-part
                            v-model="form"
                            @tabErrorsContainer="
                                mergeTabErrorsContainer($event)
                            "
                        ></general-form-part>
                    </v-tab-item>
                </v-tabs-items>
            </template>
        </base-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import routeType from '@/router/routeType'
import authType from '@/store/type/authType'
import ApiClientType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import FormExtend from '@/components/mixins/FormExtend'
import AuthMixin from '@/components/mixins/AuthMixin'
import GeneralFormPart from './GeneralFormPart'
import configType from '@/store/type/configType'

export default {
    components: {
        GeneralFormPart,
    },
    mixins: [FormExtend, AuthMixin],
    data() {
        return {
            api: ApiClientType.TAG_GROUPS,
            mapper: MapperType.TAG_GROUP_FORM,
            form: {
                translations: {},
                createdAt: '',
                updatedAt: '',
            },
            editRoute: routeType.TAG_GROUP_EDIT,
            listRoute: routeType.TAG_GROUP_LIST,
        }
    },
    computed: {
        ...mapGetters({
            userId: authType.getters.USER_ID,
            currentLocale: configType.getters.CURRENT_LOCALE,
        }),
        title: function () {
            return this.form.translations[this.currentLocale]?.name
                ? this.form.translations[this.currentLocale].name
                : 'labels.tag_group'
        },
        activeTab: {
            get() {
                return parseInt(this.$route.query.tab) || 0
            },
            set(val) {
                this.$router.replace({ query: { tab: val } })
            },
        },
    },
    methods: {
        editRedirectParams: (data) => ({ id: data.id }),
    },
}
</script>
