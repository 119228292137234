<template>
    <div style="width: 100%">
        <!-- GENERAL -->
        <form-panel>
            <form-one-col-row v-if="form.translations">
                <!-- NAME -->
                <trans-textarea-input
                    v-model="form.translations"
                    v-tab-error:general
                    validators="space"
                    field="name"
                    name="name"
                    :error-messages="errors.collect('name')"
                    label="labels.name"
                ></trans-textarea-input>
            </form-one-col-row>
            <form-two-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model="form.createdAt"
                        v-tab-error:general
                        name="createdAt"
                        label="labels.created_at"
                        :disabled="true"
                        :error-messages="errors.collect('createdAt')"
                        :data-vv-as="$t('labels.created_at')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <text-input
                        v-model="form.updatedAt"
                        v-tab-error:general
                        name="updatedAt"
                        label="labels.updated_at"
                        :disabled="true"
                        :error-messages="errors.collect('updatedAt')"
                        :data-vv-as="$t('labels.updated_at')"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AuthMixin from '@/components/mixins/AuthMixin'
import FormPartMixin from '@/components/mixins/FormPartMixin'
import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import FormOneColRow from '@/components/form/FormOneColRow'
import TransTextareaInput from '@/components/form/Inputs/TransTextareaInput'
import TextInput from '@/components/form/Inputs/TextInput'
import configType from '@/store/type/configType'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        TextInput,
        TransTextareaInput,
        FormOneColRow,
    },
    mixins: [FormPartMixin, AuthMixin, PhoneNumberMixin, FormTabMixin],

    computed: {
        ...mapGetters({
            availableLocales: configType.getters.LOCALES,
        }),
    },
}
</script>
