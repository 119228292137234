<template>
    <div>
        <taggroup-form></taggroup-form>
    </div>
</template>

<script>
import taggroupForm from '@/components/pages/tagGroups/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        taggroupForm,
    },

    mixins: [dirtyForm],
}
</script>
